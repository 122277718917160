import { useEffect, useState } from 'react';

// If needed this can be pushed into some sort of a config file, env variable, or CMS for easier control
const isThemeDetectorEnabled = false;
export const useThemeDetector = (): {
  theme: string;
  enabled: boolean;
} => {
  const [isMounted, setIsMounted] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(() => {
    if (typeof window !== 'undefined') {
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    return false;
  });
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMounted(true);
      const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
      const mqListener = (e: MediaQueryListEvent): void => {
        setIsDarkTheme(e.matches);
      };
      darkThemeMq.addEventListener('change', mqListener);
      return (): void => darkThemeMq.removeEventListener('change', mqListener);
    }
  }, []);
  if (!isThemeDetectorEnabled) {
    return {
      theme: 'dark',
      enabled: isThemeDetectorEnabled
    };
  }
  return {
    theme: isMounted && !isDarkTheme ? 'light' : 'dark',
    enabled: isThemeDetectorEnabled
  };
};
export default useThemeDetector;