'use client';

import { useTranslations } from 'next-intl';
import React from 'react';
export const Footer: React.FC = (): React.ReactElement => {
  const t = useTranslations('Footer');
  return <footer className={'flex flex-col items-center gap-3'} data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <div className={'mt-8 max-w-[744px] text-center text-sm font-light opacity-50'}>
        {t('CompanyInfo')}
      </div>
      <div className={'flex flex-row items-center justify-center gap-3 text-xs font-medium tracking-wide opacity-70'}>
        <a href={'#'}>{t('Navigation.About')}</a>
        <svg fill="none" height="12" viewBox="0 0 1 12" width="1" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Footer.tsx">
          <rect fill="#A5AABE" height="12" width="1" data-sentry-element="rect" data-sentry-source-file="Footer.tsx" />
        </svg>
        <a href={'#'}>{t('Navigation.Support')}</a>
      </div>
    </footer>;
};