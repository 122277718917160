import React from 'react';
export const MoneliqHeaderLogo = (): React.ReactElement => <svg fill="none" height="25" viewBox="0 -4 164 34" width="132" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="MoneliqHeaderLogo" data-sentry-source-file="MoneliqHeaderLogo.tsx">
    <g id="moneliq_logo" data-sentry-element="g" data-sentry-source-file="MoneliqHeaderLogo.tsx">
      <path d="M132 25H128V0H132V25Z" fill="white" id="Vector" data-sentry-element="path" data-sentry-source-file="MoneliqHeaderLogo.tsx" />
      <path d="M63.7566 4.35405C63.767 4.21319 63.8228 4.07945 63.9158 3.97283C64.0088 3.8662 64.134 3.79242 64.2726 3.76251C64.4113 3.73261 64.5559 3.74818 64.6849 3.8069C64.8139 3.86563 64.9203 3.96435 64.9883 4.08831L73.7331 24.9591H81V0.327065H77.305V23.9166L68.3959 2.71872C68.0754 1.91667 67.5207 1.22881 66.8034 0.744125C66.0861 0.259437 65.2393 0.000219141 64.3724 0C63.2128 0 62.1006 0.458729 61.2807 1.27527C60.4607 2.09181 60 3.19928 60 4.35405V25H63.7566V4.35405Z" fill="white" id="Vector_2" data-sentry-element="path" data-sentry-source-file="MoneliqHeaderLogo.tsx" />
      <path d="M113.44 21.2717C113.229 21.2718 113.02 21.2309 112.825 21.1516C112.63 21.0722 112.454 20.9559 112.305 20.8095C112.157 20.663 112.04 20.4893 111.961 20.2984C111.883 20.1076 111.843 19.9033 111.846 19.6976V0H108V19.6976C108 20.3939 108.141 21.0834 108.414 21.7267C108.687 22.3701 109.088 22.9546 109.593 23.447C110.098 23.9393 110.698 24.3299 111.358 24.5964C112.018 24.8629 112.726 25 113.44 25H125V21.251H113.44V21.2717Z" fill="white" id="Vector_3" data-sentry-element="path" data-sentry-source-file="MoneliqHeaderLogo.tsx" />
      <path d="M103 3.8111V0H86V19.6976C86 21.1039 86.5731 22.4526 87.5933 23.447C88.6135 24.4414 89.9972 25 91.44 25H102.979V21.251H91.44C91.0153 21.2511 90.6077 21.0881 90.3055 20.7973C90.0032 20.5066 89.8306 20.1115 89.825 19.6976V14.3538H103V10.6048H89.825V3.8111H103Z" fill="white" id="Vector_4" data-sentry-element="path" data-sentry-source-file="MoneliqHeaderLogo.tsx" />
      <path d="M44.021 25C41.448 25.004 38.9317 24.2737 36.7907 22.9018C34.6496 21.5298 32.9801 19.5777 31.9936 17.2928C31.007 15.0079 30.7478 12.493 31.2488 10.0663C31.7497 7.63968 32.9883 5.41054 34.8076 3.66117C36.627 1.9118 38.9453 0.72088 41.469 0.239208C43.9927 -0.242464 46.6083 0.00677636 48.9846 0.95537C51.3609 1.90396 53.391 3.50924 54.8178 5.56794C56.2447 7.62664 57.0041 10.0462 57 12.5201C56.9944 15.8284 55.6252 18.9996 53.1924 21.3388C50.7595 23.6781 47.4615 24.9947 44.021 25ZM44.021 3.64918C42.1954 3.64519 40.4096 4.16207 38.8897 5.1344C37.3697 6.10672 36.184 7.49078 35.4825 9.11138C34.781 10.732 34.5953 12.5163 34.9489 14.2384C35.3025 15.9605 36.1795 17.5431 37.4689 18.7857C38.7584 20.0283 40.4022 20.8752 42.1924 21.2192C43.9826 21.5631 45.8387 21.3886 47.5257 20.7178C49.2127 20.0469 50.6548 18.9099 51.6695 17.4507C52.6842 15.9914 53.2258 14.2755 53.2258 12.5201C53.2203 10.1725 52.2494 7.9223 50.5249 6.2604C48.8005 4.5985 46.4624 3.65982 44.021 3.64918Z" fill="white" id="Vector_5" data-sentry-element="path" data-sentry-source-file="MoneliqHeaderLogo.tsx" />
      <path d="M143.715 20.8873L142.036 24.2583L160.24 33.0935L161.919 29.7225L143.715 20.8873Z" fill="url(#paint0_linear_115_29057)" id="Vector_6" data-sentry-element="path" data-sentry-source-file="MoneliqHeaderLogo.tsx" />
      <path d="M143.962 21.0163L152.194 25C155.042 24.0411 157.465 22.1361 159.05 19.6099C160.634 17.0836 161.284 14.0925 160.886 11.1464C160.488 8.20026 159.069 5.48155 156.87 3.4537C154.67 1.42585 151.827 0.214405 148.825 0.0258762C145.822 -0.162653 142.847 0.683409 140.405 2.41983C137.963 4.15625 136.207 6.67554 135.435 9.54822C134.663 12.4209 134.924 15.4691 136.172 18.1733C137.421 20.8775 139.58 23.0701 142.282 24.3775L143.962 21.0163ZM139.761 8.81605C140.567 7.20041 141.84 5.85714 143.419 4.95603C144.997 4.05493 146.811 3.63645 148.63 3.75351C150.45 3.87057 152.193 4.51791 153.64 5.61369C155.087 6.70946 156.173 8.20449 156.76 9.90976C157.347 11.615 157.409 13.454 156.938 15.1942C156.468 16.9343 155.485 18.4976 154.116 19.6863C152.746 20.875 151.05 21.6358 149.243 21.8726C147.435 22.1093 145.598 21.8113 143.962 21.0163C141.77 19.9452 140.098 18.06 139.311 15.7733C138.524 13.4866 138.686 10.9849 139.761 8.81605Z" fill="white" id="Vector_7" data-sentry-element="path" data-sentry-source-file="MoneliqHeaderLogo.tsx" />
      <path d="M21.0053 0.11413L14.0105 23.3506L7.70893 2.50581C7.37939 1.66847 6.76688 0.967607 5.97387 0.520506C5.18087 0.0734058 4.25549 -0.0928109 3.35257 0.0496686C2.44965 0.192148 1.62398 0.63468 1.01369 1.30322C0.403414 1.97175 0.0455565 2.82574 0 3.72227L0 25H3.80195V3.72227C3.81915 3.68741 3.84601 3.65801 3.87944 3.63744C3.91288 3.61687 3.95154 3.60596 3.991 3.60596C4.03046 3.60596 4.06912 3.61687 4.10255 3.63744C4.13599 3.65801 4.16285 3.68741 4.18005 3.72227L10.5026 25H17.4344L24.1981 2.32025V25H28V0.11413H21.0053Z" fill="white" id="Vector_8" data-sentry-element="path" data-sentry-source-file="MoneliqHeaderLogo.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="MoneliqHeaderLogo.tsx">
      <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_115_29057" x1="143.467" x2="160.936" y1="22.6871" y2="32.0405" data-sentry-element="linearGradient" data-sentry-source-file="MoneliqHeaderLogo.tsx">
        <stop stopColor="white" data-sentry-element="stop" data-sentry-source-file="MoneliqHeaderLogo.tsx" />
        <stop offset="1" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="MoneliqHeaderLogo.tsx" />
      </linearGradient>
    </defs>
  </svg>;