'use client';

import { NextUIProvider } from '@nextui-org/system';
import clsx from 'clsx';
import { Inter } from 'next/font/google';
import { NextIntlClientProvider, type AbstractIntlMessages } from 'next-intl';
import React from 'react';
import { BackgroundGlow, Footer, Header } from '@/components/Layout';
import { useThemeDetector } from '@/hooks';
const inter = Inter({
  subsets: ['latin']
});
type CoreLayoutProps = {
  children: React.ReactNode;
  locale: string;
  timeZone: string;
  messages: AbstractIntlMessages;
};
export const CoreLayout: React.FC<CoreLayoutProps> = ({
  children,
  locale,
  timeZone,
  messages
}): React.ReactElement => {
  const {
    theme,
    enabled
  } = useThemeDetector();
  return <html className={clsx('h-screen bg-center bg-no-repeat text-foreground light:bg-light-arc-gradient dark:bg-dark-arc-gradient', theme, !enabled && 'bg-dark-arc-gradient')} lang={locale} data-sentry-component="CoreLayout" data-sentry-source-file="CoreLayout.tsx">
      <body className={clsx(inter.className, 'min-h-screen pb-16')}>
        <NextIntlClientProvider locale={locale} messages={messages} timeZone={timeZone} data-sentry-element="NextIntlClientProvider" data-sentry-source-file="CoreLayout.tsx">
          <NextUIProvider data-sentry-element="NextUIProvider" data-sentry-source-file="CoreLayout.tsx">
            <Header data-sentry-element="Header" data-sentry-source-file="CoreLayout.tsx" />
            <main className={'flex h-max flex-col items-center justify-center'}>{children}</main>
            <Footer data-sentry-element="Footer" data-sentry-source-file="CoreLayout.tsx" />
          </NextUIProvider>
        </NextIntlClientProvider>
        <BackgroundGlow data-sentry-element="BackgroundGlow" data-sentry-source-file="CoreLayout.tsx" />
      </body>
    </html>;
};