import React from 'react';

type UseDebounce = <T>(value: T, delay?: number) => T;

export const useDebounce: UseDebounce = <T>(value: T, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

  React.useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return (): void => clearTimeout(timer);
  }, [value, delay]);

  return debouncedValue;
};
