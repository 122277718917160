'use client';

import clsx from 'clsx';
import React from 'react';
import { useThemeDetector } from '@/hooks';
export const CheckoutLayout = ({
  children
}: Readonly<{
  children: React.ReactNode;
}>): React.ReactElement => {
  const {
    theme,
    enabled
  } = useThemeDetector();
  return <div className={clsx('z-10 flex min-h-[685px] w-[744px] max-w-full flex-col items-center justify-start rounded-[24px] p-8 dark light:bg-beige dark:bg-black', theme, !enabled && 'bg-black')} data-sentry-component="CheckoutLayout" data-sentry-source-file="CheckoutLayout.tsx">
      {children}
    </div>;
};